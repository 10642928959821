import * as React from "react";
import { render } from "react-dom";
import { HashRouter, Switch, Route } from "react-router-dom";
import routeConfig from "./routeConfig";
import Spinner from "react-spinner-material";
import { Auth0Provider } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import "reset-css";
import "./components/styles/base.css";

const routeLoadingFallback = (
  <Spinner radius={75} color={"pink"} stroke={2} visible={true} />
);

function AuthenticatedComponent({
  children,
  isAuthRequired
}: {
  isAuthRequired?: boolean;
  children: React.ReactElement<unknown>;
}) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (isAuthRequired && !isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, isAuthRequired, loginWithRedirect]);

  if (isLoading) {
    return null;
  }
  return !isAuthRequired || isAuthenticated ? children : null;
}

const App = () => (
  <Auth0Provider
    domain="joyco.us.auth0.com"
    clientId="kaxQz70By2S03IjbXbsBiakuJiKcINIg"
    authorizationParams={{
      redirect_uri: `${window.location.origin}/#/wedding/profile`
    }}
  >
    <HashRouter>
      <Switch>
        {routeConfig.map(({ isAuthRequired, path, component: Component }) => (
          <Route
            key={path}
            path={path}
            children={
              <React.Suspense fallback={routeLoadingFallback}>
                <AuthenticatedComponent isAuthRequired={isAuthRequired}>
                  <Component />
                </AuthenticatedComponent>
              </React.Suspense>
            }
          />
        ))}
      </Switch>
    </HashRouter>
  </Auth0Provider>
);

const rootElement = document.getElementById("root");
render(<App />, rootElement);
