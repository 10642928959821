import React from "react";

export enum RouteNames {
  HOME = "home",
  MAIN_MENU = "mainMenu",
  PUFF = "puff",
  PUFF_SELECT_RESTAURNT = "puff/select-restaurant",
  PUFF_CONFIRM_RESTAURANT = "puff/confirm-restaurant/:id",
  MOVE_IT = "moveit",
  WEDDING_PROFILE = "wedding/profile"
}

const routeConfig: Array<{
  name: RouteNames;
  path: string;
  isAuthRequired?: boolean;
  component: React.LazyExoticComponent<() => JSX.Element>;
}> = [
  {
    name: RouteNames.PUFF_SELECT_RESTAURNT,
    path: "/puff/select-restaurant",
    component: React.lazy(() =>
      import("./components/apps/puff/SelectRestaurant")
    )
  },
  {
    name: RouteNames.PUFF_CONFIRM_RESTAURANT,
    path: "/puff/confirm-restaurant/:restaurantId",
    component: React.lazy(() =>
      import("./components/apps/puff/ConfirmRestaurant")
    )
  },
  {
    name: RouteNames.PUFF,
    path: "/puff",
    component: React.lazy(() => import("./components/apps/puff/JigglyPuff"))
  },
  {
    name: RouteNames.MOVE_IT,
    path: "/moveit",
    component: React.lazy(() => import("./components/apps/moveit/Game"))
  },
  {
    name: RouteNames.WEDDING_PROFILE,
    path: "/wedding/profile",
    isAuthRequired: true,
    component: React.lazy(() =>
      import("./components/apps/wedding/WeddingProfile")
    )
  },
  {
    name: RouteNames.MAIN_MENU,
    path: "/",
    component: React.lazy(() => import("./components/MainMenu"))
  }
];

export default routeConfig;
